<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>

                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL BANCO DE NORMAS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico far fa-folder-open fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaCategorias.length}}</span>
                            <br>
                            <span class="text-muted">Categorías</span>
                        </b-col>
                        <b-col cols="6" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico far fa-file-alt fa-3x"></i>
                            <br>
                            <span class="lg-numero ">{{count}}</span>
                            <br>
                            <span class="text-muted">Documentos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de categorías</span>
                </CCardHeader>

                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('001-DOC-BAN','c') == 1"  sm="4" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarCategoria = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva categoría
                            </b-button>
                        </b-col>
                        <b-col  v-if="checkPermissions('001-DOC-BAN-ETI','r') == 1"  sm="4" class="my-2">
                            <b-button block :to="{name: 'Etiquetas'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-tags fa-3x pb-1"></i><br>Etiquetas
                            </b-button>
                        </b-col>
                        <b-col sm="4" class="my-2">
                            <b-button block  v-if="checkPermissions('001-DOC-BAN','r') == 1" :to="{name: 'Buscar por etiquetas'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-search fa-3x pb-1"></i><br>Buscar por etiquetas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Categorías registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página:" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaCategorias" :fields="campoCategorias" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(nombre)="data">
                                    <router-link  v-if="checkPermissions('001-DOC-BAN-DOC','r') == 1" :to="{name: 'Ver documentos coordinador', params: {id: data.item.idCategoriaNorma, name: data.item.nombre}}" style="text-decoration: none;">
                                        <i class="fas fa-folder"></i> <span>{{data.item.nombre}}</span>
                                    </router-link>
                                       <span  v-if="checkPermissions('001-DOC-BAN-DOC','r') != 1"> <i class="fas fa-folder"></i> {{data.item.nombre}}</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('001-DOC-BAN-DOC','r') == 1"  :to="{name:'Ver documentos coordinador', params: {id: param.item.idCategoriaNorma, name: param.item.nombre}}" class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Abrir carpeta'">
                                        <i class="fas fa-folder-open  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('001-DOC-BAN','u') == 1" @click="abrirModalActualizarCategoria(param)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('001-DOC-BAN','d') == 1"  @click="eliminarCategoria(param)" size="sm" variant="danger" class="mr-1 mb-1" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template v-slot:cell(fechaCreacion)="data">
                                    {{ data.item.fechaCreacion.substr(0,10)}}
                                </template>
                                <template v-slot:cell(fechaModificacion)="data">
                                    {{ data.item.fechaModificacion.substr(0,10)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalRegistrarCategoria">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva categoría </span>
            </h6>
            <CButtonClose @click="modalRegistrarCategoria = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCategoria)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosCategoria.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de publicación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosCategoria.fechaCreacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" placeholder="Ingrese la descripción" v-model="datosCategoria.descripcion"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarCategoria = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalActualizarCategoria">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar categoría</span>
            </h6>
            <CButtonClose @click="modalActualizarCategoria = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCategoria)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosActualizarCategoria.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de publicación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarCategoria.fechaCreacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{required: true}">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" placeholder="Ingrese la descripción" v-model="datosActualizarCategoria.descripcion"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-col class="my-2 text-right" md="12">
                            <b-button size="md" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button size="md" variant="danger" @click="modalActualizarCategoria = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            count: '',
            modalRegistrarCategoria: false,
            modalActualizarCategoria: false,

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoCategorias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha de creación",
                    class: "text-center",
                },
                {
                    key: "nombrePersona",
                    label: "Propietario",
                    class: "text-center",
                },
                {
                    key: "cantidadDocumentos",
                    label: "Documentos",
                    class: "text-center",
                },
                {
                    key: "fechaModificacion",
                    label: "Última modificación",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            listaCategorias: [],
            datosCategoria: {
                idCliente: '',
                idUsuario: '',
                nombre: '',
                fechaCreacion: moment().format('YYYY-MM-DD'),
                descripcion: '',
            },
            datosActualizarCategoria: {
                idCategoriaNorma: '',
                idCliente: '',
                idUsuario: '',
                nombre: '',
                fechaCreacion: '',
                descripcion: '',
            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        contarDocumentoNormas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/contar-documentos-normas", {
                        params: {
                            idCliente: me.datosCategoria.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.count = response.data[0].count;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarCategorias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-categorias-normas", {
                        params: {
                            idCliente: me.datosCategoria.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaCategorias = response.data;
                    me.totalRows = me.listaCategorias.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarCategoria() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-categoria-norma",
                    me.datosCategoria, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarCategoria = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarCategorias();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarCategoria() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-categoria-norma",
                    me.datosActualizarCategoria, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarCategoria = false;
                    me.listarCategorias();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        eliminarCategoria(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la categoría?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-categoria-norma", {
                                idCategoriaNorma: param.item.idCategoriaNorma
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarCategorias();
                            me.contarDocumentoNormas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarCategoria(param) {
            let me = this;
            me.datosActualizarCategoria.idCategoriaNorma = param.item.idCategoriaNorma;
            me.datosActualizarCategoria.nombre = param.item.nombre;
            me.datosActualizarCategoria.descripcion = param.item.descripcion;
            me.datosActualizarCategoria.fechaCreacion = param.item.fechaCreacion.substr(0, 10);

            me.modalActualizarCategoria = true;

        },
        resetModalRegistrarCategoria() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCategoria.nombre = '';
            this.datosCategoria.descripcion = '';
            this.datosCategoria.fechaCreacion = moment().format('YYYY-MM-DD');
        },
        resetModalActualizarCategoria() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalRegistrarCategoria: function (val) {
            if (val == false) {
                this.resetModalRegistrarCategoria();
            }
        },
        modalActualizarCategoria: function (val) {
            if (val == false) {
                this.resetModalActualizarCategoria();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCategoria.idCliente = this.datosActualizarCategoria.idCliente = user.uidClient;
            this.datosCategoria.idUsuario = this.datosActualizarCategoria.idUsuario = user.uid;
            this.listarCategorias();
            this.contarDocumentoNormas();
        }
    }

}
</script>
